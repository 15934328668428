import { Grid, Paper, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import dataProvider from "../../../providers/data-provider";
import { Spacer } from "../../spacer";

const QuizInstitutionQuestionDatabaseInfo = () => {

    useEffect(() => {
        const fetchData = async () => {
            await dataProvider.get('questions', 'question-database-report').then((res) => setQuestionData(res.data))
        }
        fetchData();
    }, [])

    const [questionData, setQuestionData] = useState(null)

    const showableQuestions = questionData?.filter(item => item.questions > 0).sort((a, b) => b.questions - a.questions)
    const totalQuestions = questionData?.map(i => i.questions).reduce((prev, cur) => prev + cur)


    return (
        <Grid container xs={12} style={{ marginTop: '16px' }}>
            <Paper>
                <Grid item style={{ paddingBlock: '32px', paddingInline: '16px' }}>
                    <Typography variant="h5" style={{ marginBottom: '8px' }}>Banco de questões</Typography>
                    <Grid container justifyContent="space-between">
                        {showableQuestions?.map(item => (
                            <Grid item container direction="column" style={{ backgroundColor: '#1f1f1f', flexBasis: "220px", marginBlock: '4px', padding: '8px', borderRadius: '8px' }}>
                                <Typography align="center" color="textSecondary">{item.name}</Typography>
                                <Spacer />
                                <Typography variant={'h6'} align="center" style={{ alignSelf: 'stretch' }}>{item.questions}</Typography>
                            </Grid>
                        ))}
                        <Grid item style={{ backgroundColor: '#1f1f1f', marginBlock: '4px', padding: '8px', borderRadius: '8px', flexBasis: "220px" }}>
                            <Typography align="center" color="textSecondary">{"Total"}</Typography>
                            <Typography variant={'h6'} align="center">{`${totalQuestions}`}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default QuizInstitutionQuestionDatabaseInfo