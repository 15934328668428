import { Avatar, Badge, Card, CardContent, CardHeader, Divider, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@material-ui/core"
import { Delete, Markunread, MoreVert } from "@material-ui/icons"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { Loading, Title, useRedirect } from "react-admin"
import dataProvider from "../../providers/data-provider"
import { useAuthenticated } from "react-admin";
import { AppContext } from "../../providers/app-context-provider/app.context"
import { replace_params } from "../../utils/functions"


const Sidemenu = (props) => {

    const { notification, setRead, setDeleted } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const markUnread = (id) => {
        dataProvider.post('notifications', 'markAsUnread', { id }).then(() => setRead(false))
    };

    const deleteNotification = (id) => {
        dataProvider.delete('notifications', { id: id }).then(() => setDeleted(true))
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleOpen}>
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    notification.read &&
                    <MenuItem onClick={() => markUnread(notification.id)}>
                        <ListItemIcon> <Markunread /> </ListItemIcon>
                        <ListItemText primary={'Marcar como não lido'} />
                    </MenuItem>
                }
                <MenuItem onClick={() => deleteNotification(notification.id)}>
                    <ListItemIcon> <Delete /> </ListItemIcon>
                    <ListItemText primary={'Excluir'} />
                </MenuItem>
            </Menu>
        </>
    )
}

export const NotificationPanel = ({ notification }) => {

    const [read, setRead] = useState(notification.read)
    const [deleted, setDeleted] = useState(false)
    const redirect = useRedirect()

    const handleClick = (event, id, link) => {
        dataProvider.post('notifications', 'markAsRead', { id }).then(() => redirect(link))
    }

    return ((!notification || deleted) ? <></> :
        <ListItem>
            <Card style={{ width: '100%' }} id={notification.id}>
                <CardHeader
                    avatar={
                        <Badge color='error' variant="dot" invisible={read}>
                            <Avatar src={'https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_juristta_simples.png'} variant='rounded' />
                        </Badge>}
                    title={replace_params(notification?.type?.title, notification?.parameters)}
                    titleTypographyProps={{ style: { fontWeight: read ? 'normal' : 'bold' } }}
                    subheader={`por ${notification.product ? notification.product.name : 'Juristta'} em ${moment(notification.created_at).format('LLL')}`}
                    subheaderTypographyProps={{ style: { fontWeight: read ? 'normal' : 'bold' } }}
                    action={<Sidemenu notification={notification} setRead={setRead} setDeleted={setDeleted} />} />
                <Divider />
                <CardContent>
                    <Typography style={{ fontWeight: read ? 'normal' : 'bold' }} >{replace_params(notification?.type?.body, notification?.parameters)}</Typography>
                    {
                        notification?.redirect && <Link
                            style={{ fontWeight: read ? 'normal' : 'bold', cursor: 'pointer' }}
                            variant={'body1'}
                            onClick={(e) => handleClick(e, notification.id, notification.redirect)}
                        >
                            Clique aqui para visualizar
                        </Link>
                    }
                </CardContent>
            </Card>
        </ListItem>
    )

}


export const NotificationsPage = () => {
    useAuthenticated();
    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState(false)

    const context = useContext(AppContext)

    useEffect(() => {
        const getNotifications = async () => {
            setLoading(true)
            const res = await dataProvider.get('notifications', '', { filter: JSON.stringify({ productId: context.product.id }) })
            setNotifications(res.data)
            setLoading(false)
        }
        getNotifications();
    }, [context.product])


    return (
        <>
            <Title title={'Minhas notificações'} />
            {
                loading ? <Loading /> :
                    <List>
                        {notifications.map(notification => { console.log(notification); return <NotificationPanel notification={notification} /> })}
                    </List>
            }
        </>
    )

}
