
import { Typography } from "@material-ui/core";
import { Code, CodeBlock, CopyBlock, dracula } from "react-code-blocks";

export const CadastroTurmas = () => {

    const text = `{
    "nome": string,
    "descricao": string,
    "id_periodo": string, (id do perído letivo, pode ser consultado via API)
    "alunos": Array [
        {
            "nome": string,
            "email": string,
            "fone": string, (OPCIONAL, formato (99) 99999-9999)
        },
    ]
}`;

    const text2 = `POST /v1/turma
Host: api.app.juristta.com
Content-Type: application/json
Authorization: Bearer v1_a2f5i10a_abc123def456ghi789jk

{
    "nome": "DC I - M",
    "descricao": "Turma de Direito Civil I período Matutino.",
    "id_periodo": "8f844d51-73e9-2c4a-ba3e-8d9b734a520b",
    "alunos": [
        {
            "nome": "João Silva",
            "email": "joao.silva@example.com",
            "fone": "+5511987654321",
        },
        {
            "nome": "Maria Oliveira",
            "email": "maria.oliveira@example.com",
        }
    ]
}`;

    const text3 = `{
    "id": "9565dda7-d7c0-4846-8333-282449987c5b",
    "name": "DC I - M",
    "description": "Turma de Direito Civil I período Matutino.",
    "academic_period": {
        "id": "8f844d51-73e9-2c4a-ba3e-8d9b734a520b",
        "name": "2025/1 - Teste Cadastro API",
        "description": "Primeiro semestre 2025",
        "start_at": "2025-01-01",
        "end_at": "2024-06-30",
    },
    "users": [],
    "active": true
}`;

    return <>
        <Typography variant='h6'>Cadastro de turmas</Typography>
        <div style={{ marginBottom: '16px' }} />

        <Typography variant='body1'>Este endpoint permite a criação de uma nova turma com a opção de cadastrar e inserir alunos na mesma.</Typography>

        <div style={{ marginBlock: '16px', borderRadius: '8px', backgroundColor: '#1f1f1f', padding: '8px' }} >
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>Método:</Typography> <Typography component='span'>POST</Typography>
            </div>
            <div>
                <Typography variant="body1" component='span' style={{ fontWeight: 'bold' }}>URL:</Typography> <Typography component='span'>https://api.app.juristta.com/v1/turma </Typography>
            </div>
        </div>
        <Typography variant="h6">Parâmetros do corpo da requisição </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CopyBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text} language="http" theme={dracula} codeBlock showLineNumbers />
        </Typography>

        <Typography variant="body1">Também é possível cadastrar uma turma sem alunos, e inserí-los posteriormente via sistema ou via API pelo endpoint /inserir-em-turma.</Typography>
        <div style={{ marginBottom: '16px' }} />

        <Typography variant="h6">Exemplo de requisição </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CopyBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text2} language="http" theme={dracula} codeBlock showLineNumbers />
        </Typography>

        <div style={{ marginBottom: '16px' }} />

        <Typography variant="h6">Exemplo de resposta </Typography>
        <Typography variant="body1" style={{ fontFamily: 'monospace', marginBlock: '16px' }}>
            <CodeBlock codeBlockStyle={{ fontFamily: 'Arial' }} text={text3} language="json" theme={dracula} />
        </Typography>
        <Typography variant="body1">Requisições que não seguirem corretamente a especificação receberão uma resposta com o status <Code language='http' text="HTTP 400 Bad Request." theme={dracula} ></Code> com detalhes do problema. </Typography>

    </>


}