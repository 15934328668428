import axios from 'axios';
import { stringify } from 'query-string';
import { getApiUrl } from '../../utils/urls';
import cookiesProvider from '../cookies-provider';
import { resourceProvider } from '../resource-provider/resource-provider';

const apiUrl = getApiUrl();

const dataProvider = {

    getList: async (resource, params) => {

        const resourceName = resourceProvider.getResource(resource).tableName;

        const token = cookiesProvider.getToken();
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify(`${resourceName}.${field}`),
            order: JSON.stringify(`${order}`),
            limit: JSON.stringify(perPage),
            page: JSON.stringify(page),
            filter: JSON.stringify(params.filter),
        };

        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
        return { data: response.data.data, total: response.data.total };
    },

    getOne: async (resource, params) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/${resource}/${params.id}`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
        return { data: response.data, total: response.data.total };
    },

    update: async (resource, params) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/${resource}/${params.id}`
        const response = await axios.patch(url, { ...params.data }, { headers: { Authorization: `Bearer ${token}` }, });
        return { data: response.data };
    },

    create: async (resource, params) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/${resource}`
        const response = await axios.post(url,
            { ...params.data },
            { headers: { Authorization: `Bearer ${token}`, 'Access-Control-Allow-Origin': 'https://app.juristta.com' } })
        if (response.data.data) {
            return response.data;
        }
        return { data: response.data }
    },

    getMany: async (resource, params) => {
        const query = {
            filter: JSON.stringify({ ids: params.ids.flat().map(v => v.id) }),
        };
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/${resource}?${stringify(query)}`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return { data: response.data.data, total: response.data.total };
    },

    getManyReference: async (resource, params) => {

        const resourceName = await resourceProvider.getResource(resource).tableName;

        const token = cookiesProvider.getToken();
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify(`${resourceName}.${field}`),
            order: JSON.stringify(`${order}`),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })

        return response.data;
    },

    delete: async (resource, params) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/${resource}/${params.id}`
        const response = await axios.delete(url, { headers: { Authorization: `Bearer ${token}` } })
        return response
    },

    getDashboard: async (resource, params) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/${resource}/${params.id}/dashboard`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response
    },

    getAnalyticReport: async (resource, params) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/${resource}/${params.id}/analyticReport`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response
    },

    getExternalServiceRequestByUUID: async (resource, params) => {
        const url = `${apiUrl}/${resource}/guest/${params.uuid}`
        const response = await axios.get(url)
        return response
    },

    getTeacherProcessDashboard: async () => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/teacher/process-dashboard`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getTeacherActivityDashboard: async () => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/teacher/activity-dashboard`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getStudentProcessDashboard: async () => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/student/process-dashboard`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getStudentActivityDashboard: async () => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/student/activity-dashboard`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getUserActivityDashboard: async (id) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/student/activity-dashboard/${id}`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getClassGradeReport: async (id) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/classes/grade-report/${id}`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getStudentsWithActiveClass: async () => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/student/active`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },


    getStudents: async () => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/student/`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getClasses: async () => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/classes/`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    globalSearch: async (filter, value) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/search?filter=${filter}&value=${value}`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    dailyMail: async () => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/mail/sendDailyMail`
        const response = await axios.post(url, { pwd: 'hollow-knight' }, { headers: { Authorization: `Bearer ${token}` } })
        if (response.data.data) {
            return response.data;
        }
        return { data: response.data }
    },

    getActivityNotes: async (id) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/activities/${id}/getActivityNotes`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getProcessNotes: async (id) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/processes/${id}/getProcessNotes`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getProcessesWithoutManager: async () => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/processes/report/processesWithoutManager`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getProcessesWithoutTeacher: async () => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/processes/report/processesWithoutTeacher`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getActivitiesWithoutGrade: async () => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/activities/report/activitiesWithoutGrade`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    requestPasswordReset: async (params) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/auth/requestPasswordReset`
        const response = await axios.post(url, { ...params.data }, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    passwordReset: async (params) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/auth/passwordReset`
        const response = await axios.post(url, { ...params.data }, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getMail: async (id) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/process-mail/${id}`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    sendFilesToProcess: async (id) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/process-mail/sendFilesToProcess/${id}`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } })
        return response;
    },

    getOneSimple: async (resource, params) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/${resource}/${params.id}/simple`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
        return { data: response.data, total: response.data.total };
    },

    getProcessSimpleByAlias: async (params) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/processes/alias/${params.alias}`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
        return { data: response.data, total: response.data.total };
    },

    sendPromptToAI: async (prompt, messages) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/openai`
        const response = await axios.post(url, { prompt, messages }, { headers: { Authorization: `Bearer ${token}` } })
        return response.data;
    },

    get: async (resource, endpoint, query) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/${resource}${endpoint ? `/${endpoint}` : ''}${query ? `?${stringify(query)}` : ''}`
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
        return { data: response.data };
    },

    post: async (resource, endpoint, data) => {
        const token = cookiesProvider.getToken();
        const url = `${apiUrl}/${resource}/${endpoint}`
        const response = await axios.post(url, { ...data }, { headers: { Authorization: `Bearer ${token}` }, });
        return { data: response.data };
    },
}

export default dataProvider;