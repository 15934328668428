import * as React from "react";
import { useAuthenticated } from "react-admin";
import { AppContext } from "../../../providers/app-context-provider/app.context";
import { storageProvider } from "../../../providers/storage-provider";
import SchoolStudentDashboard from "../school-student-dashboard";
import SchoolTeacherDashboard from "../school-teacher-dashboard";
import StudentDashboard from "../student-dashboard";
import TeacherDashboard from "../teacher-dashboard";
import QuizDashboard from "../quiz-dashboard";


export const DefaultDashboard = () => {

    useAuthenticated()

    const context = React.useContext(AppContext)
    const profiles = storageProvider.getCurrentUser()?.profiles

    if (profiles) {

        const isStudent = profiles[0]?.name === 'Aluno'
        let tutorialCompletion, tutorialComplete;

        switch (context.product.id) {
            case 1:
                //Juristta School
                tutorialCompletion = isStudent ? context.schoolStudentTutorialCompletion : context.schoolManagerTutorialCompletion;
                tutorialComplete = tutorialCompletion ? tutorialCompletion?.findIndex(el => el === false) === -1 : false
                if (isStudent) {
                    return <SchoolStudentDashboard tutorialComplete={tutorialComplete} />
                } else {
                    return <SchoolTeacherDashboard tutorialComplete={tutorialComplete} />
                }
            case 2:
                //Juristta Office
                tutorialCompletion = isStudent ? context.officeStudentTutorialCompletion : context.officeStudentTutorialCompletion;
                tutorialComplete = tutorialCompletion ? tutorialCompletion?.findIndex(el => el === false) === -1 : false
                if (isStudent) {
                    return <StudentDashboard tutorialComplete={tutorialComplete} />
                }
                else {
                    return <TeacherDashboard tutorialComplete={tutorialComplete} />
                }

            case 4:
                //Juristta Quiz
                return <QuizDashboard isStudent={isStudent}/>
            default:
                break;
        }

    } else {
        return <></>
    }
}