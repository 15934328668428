export const SchoolManagerTutorialSteps = [
    { id: 0, title: 'Visão geral do sistema', url: 'DcXOBjIe6Ig' },
    { id: 1, title: 'Como criar uma atividade matriz', url: 'AAlDjqwE6e0' },
    { id: 2, title: 'Configurando uma atividade prática', url: 'd4gogJ0O9ak' },
    { id: 3, title: 'Como corrigir uma atividade', url: '6vqduqq1mwo' },
    { id: 4, title: 'Como criar e vincular uma orientação padrão', url: 'hC_9LSdd3h8' },
    { id: 5, title: 'Metaverso: criação de conta', url: 'q0piKVL3r4s' },
    { id: 6, title: 'Metaverso: movimentação do avatar', url: '8DVvBO7wbpo' }
]

export const SchoolStudentTutorialSteps = [
    { id: 0, title: 'Visão geral do aluno e entrega de atividade', url: '1yvqFLedTSo' },
    { id: 1, title: 'Metaverso: criação de conta', url: 'q0piKVL3r4s' },
    { id: 2, title: 'Metaverso: movimentação do avatar', url: '8DVvBO7wbpo' }
]

export const OfficeStudentTutorialSteps = [
    { id: 0, title: 'Como fazer login', url: 'LFTMcFKZlWg', },
    { id: 1, title: 'Dashboard', url: '-zEXFWp67pw', },
    { id: 2, title: 'Linha do tempo da atividade', url: 'k-RubNjHJwY', },
    { id: 3, title: 'Entrega de uma atividade/movimentação', url: 'HJVcdrgzFww', },
    { id: 4, title: 'Incluindo notas e anexos no atendimento', url: 'WmgwhwCyBZs', },
    { id: 5, title: 'Inserindo partes em um processo', url: '_1qdMB5WvRY', }
]

export const OfficeManagerTutorialSteps = [
    { id: 0, title: 'Como fazer login', url: 'LFTMcFKZlWg', },
    { id: 1, title: 'Visão geral da plataforma', url: 'otTczcCy7bQ', },
    { id: 2, title: 'Configuração de período letivo e turmas', url: '3TMMbm9O5KU', },
    { id: 3, title: 'Classificação de tipos de processo', url: 'uLVD3etIu-w', },
    { id: 4, title: 'Triagem dos pedidos de atendimento', url: 'AL1aG6zPX1g', },
    { id: 5, title: 'Atribuir um atendimento a um gestor/aluno', url: '4aR9Ytx-IH8', },
    { id: 6, title: 'Notas e anexos no atendimento', url: 'FwvhFjyR7mU', },
    { id: 7, title: 'Partes em um processo', url: 'Dt_RaSYYMyc', },
    { id: 8, title: 'Número do processo num atendimento', url: 'P7VG-p9Q5j0', },
    { id: 9, title: 'Corrigindo uma atividade', url: 'jfPvRQJDBwA', },
    { id: 10, title: 'Registro de audiência', url: 'IP6FPDgIy-8' },
    { id: 11, title: 'Envio de notificações', url: '_ZXOZfTh_Kw', },
]

export const QuizStudentTutorialSteps = [{ id: 0, title: 'Como utilizar o Juristta Quiz', url: 'RSNqgI1TtW0', }];

export const QuizManagerTutorialSteps = [{ id: 0, title: 'Como utilizar o Juristta Quiz', url: '-oCGWfNFWfY', }];