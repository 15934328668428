import { Avatar, Badge, Button, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, Popover } from '@material-ui/core';
import { ArrowForward, KeyboardArrowRight, Notifications } from '@material-ui/icons';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useContext, useEffect, useState } from 'react';
import { useRedirect } from 'react-admin';
import { AppContext } from '../providers/app-context-provider/app.context';
import dataProvider from "../providers/data-provider";
import { replace_params } from '../utils/functions';

export const NotificationsAppBar = () => {

    const redirect = useRedirect();
    const context = useContext(AppContext)
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        const getNotifications = async () => {
            const res = await dataProvider.get('notifications', '', { filter: JSON.stringify({ productId: context.product.id }) })
            setNotifications(res.data)
        }
        getNotifications();
    }, [popoverAnchorEl, context.product.id])

    const handleClick = (event, id, link) => {
        dataProvider.post('notifications', 'markAsRead', { id }).then(() => redirect(link))
    }

    const goToNotificationPage = () => {
        setPopoverAnchorEl(null);
        redirect(`/notifications/`)
    }

    const handlePopoverClick = (event) => {
        setPopoverAnchorEl(event.currentTarget);
    }

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null);
    }

    const unread = notifications?.filter(n => n.read === false)?.length

    return (
        <>
            <IconButton color='inherit' onClick={handlePopoverClick}>
                <Badge color='error' overlap='rectangular' badgeContent={unread} max={9}>
                    <Notifications />
                </Badge>
            </IconButton>
            <Popover
                anchorEl={popoverAnchorEl}
                open={Boolean(popoverAnchorEl)}
                onClose={handlePopoverClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
                transformOrigin={{ vertical: 'top', horizontal: 'right', }}
            >
                <List style={{ width: '600px' }} dense>
                    <ListItem key={'notifications'}>
                        <ListItemIcon>
                            <Notifications />
                        </ListItemIcon>
                        <ListItemText primary={`Notificações recentes ${unread > 0 ? `(${unread} não lidas)` : ''}`} />
                    </ListItem>
                    <Divider />
                    {
                        notifications && notifications.map((notification, idx) => {
                            return <ListItem key={`notification-${idx + 1}`}>
                                <ListItemAvatar>
                                    <Badge color='error' variant='dot' overlap='rectangular' invisible={notification.read}>
                                        <Avatar src={'https://game-juridico.s3.sa-east-1.amazonaws.com/assets/logo_juristta_simples.png'} variant='rounded' />
                                    </Badge>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={replace_params(notification?.type?.title, notification.parameters)}
                                    primaryTypographyProps={{ style: { fontWeight: notification.read ? 'normal' : 'bold' } }}
                                    secondary={moment(notification.created_at).fromNow()}
                                    secondaryTypographyProps={{ style: { fontWeight: notification.read ? 'normal' : 'bold' } }}
                                />
                                <ListItemSecondaryAction>
                                    {
                                        notification?.redirect && <IconButton
                                            onClick={(e) => handleClick(e, notification.id, notification.redirect)}
                                        >
                                            <ArrowForward />
                                        </IconButton>
                                    }
                                </ListItemSecondaryAction>
                            </ListItem>
                        })}
                    <Divider />
                    <ListItem key={'all-notifications'}>
                        <Button onClick={goToNotificationPage} startIcon={<KeyboardArrowRight />}>
                            Todas as notificações
                        </Button>
                    </ListItem>
                </List>
            </Popover>
        </>
    )

}