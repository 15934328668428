import { Button, InputBase, Menu, MenuItem, Typography } from '@material-ui/core';
import { /*Brightness4, Brightness7,*/ ExpandMore } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import 'moment/locale/pt-br';
import React, { useContext, useState } from 'react';
import { AppBar, useRedirect } from 'react-admin';
import { AppContext } from '../providers/app-context-provider/app.context';
import { storageProvider } from '../providers/storage-provider';
//import { officeDarkTheme, officeLightTheme, schoolDarkTheme, schoolLightTheme } from '../themes';
import { ProfileInfoComponent } from '../components/profile-info';
import { NotificationsAppBar } from './notifications-app-bar.component';
import { useStyles } from './styles';
export const CustomAppBar = (props) => {

    const classes = useStyles();
    const redirect = useRedirect();
    const appContext = useContext(AppContext)

    const [value, setValue] = useState('')
    const [filter, setFilter] = useState('name')
    const [anchorEl, setAnchorEl] = useState(null);

    const userProfile = storageProvider.getCurrentUser()?.profiles

    const student = userProfile?.find((profile) => profile.name === 'Aluno')

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (value) => {
        setFilter(value)
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const translateFilter = (value) => {
        switch (value) {
            case 'name':
                return 'Nome';
            case 'cpf':
                return 'CPF/CNPJ'
            case 'phone':
                return 'Telefone'
            case 'service':
                return 'Protocolo'
            case 'process':
                return 'Processo'
            case 'email':
                return 'Email';
            default:
                break;
        }
    }

    const handleSubmit = () => { redirect(`/search?filter=${filter}&value=${value}`) }


    /* const toggleTheme = () => {
         const product = appContext.product
         const currentTheme = appContext.theme.palette.type
         if (currentTheme === 'dark') {
             if (product?.id === 1) {
                 appContext.setTheme(schoolLightTheme)
             } else {
                 appContext.setTheme(officeLightTheme)
             }
             cookiesProvider.setPreferredTheme('light')
         } else {
             if (product?.id === 1) {
                 appContext.setTheme(schoolDarkTheme)
             } else {
                 appContext.setTheme(officeDarkTheme)
             }
             cookiesProvider.setPreferredTheme('dark')
         }
     }*/

    return (
        <AppBar {...props} position='fixed' className={classes.appBar} logout={<ProfileInfoComponent />}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer} />

            {
                appContext.product.id === 2 && !student && <>
                    <Button aria-controls="simple-menu" color='primary' aria-haspopup="true" onClick={handleMenuClick} className={classes.searchButton} variant='contained'>
                        {translateFilter(filter)}
                        <ExpandMore />
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem key='search-menu-item-1' onClick={() => handleMenuClose('name')}>Nome </MenuItem>
                        <MenuItem key='search-menu-item-2' onClick={() => handleMenuClose('cpf')}>CPF/CNPJ</MenuItem>
                        <MenuItem key='search-menu-item-3' onClick={() => handleMenuClose('phone')}>Telefone</MenuItem>
                        <MenuItem key='search-menu-item-4' onClick={() => handleMenuClose('service')}>Protocolo</MenuItem>
                        <MenuItem key='search-menu-item-5' onClick={() => handleMenuClose('process')}>Processo</MenuItem>
                        <MenuItem key='search-menu-item-6' onClick={() => handleMenuClose('email')}>Email</MenuItem>
                    </Menu>

                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            onChange={handleChange}
                            placeholder="Busca de atendimentos"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            value={value}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </div>
                    <Button style={{ marginLeft: '8px' }} color='inherit' className={classes.searchButton} onClick={handleSubmit} variant='contained' disabled={value.length === 0}> Buscar </Button>
                </>
            }
            <NotificationsAppBar />
            {/*     <IconButton color='inherit' onClick={toggleTheme}>
                {appContext.theme.palette.type === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </IconButton>*/}
        </AppBar >
    )

}