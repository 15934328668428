import {
    Box,
    Button,
    Container,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    Typography
} from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Loading } from "react-admin";
import { Spacer } from "../../components/spacer";
import dataProvider from "../../providers/data-provider";
import QuizQuestionReview from "./quiz-question-review.component";

const CustomStepIcon = (props) => {
    const { correct } = props;
    return (
        <div style={{ color: correct ? 'green' : 'red' }}>
            {correct ? <Check /> : <Close />}
        </div>
    );
}


const QuizReviewPage = (props) => {
    const id = props.match.params.id;

    useEffect(() => {
        const fetchData = async () => {
            await dataProvider.get("quiz", `${id}/review`)
                .then((res) => setQuizData(res.data))
        }
        fetchData();
    }, [id])

    const [quizData, setQuizData] = useState(null)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    const currentQuestion = quizData ? quizData.quiz.questions[currentQuestionIndex] : null;
    const currentAnswer = quizData ? quizData.answers.find(answer => answer.question.id === currentQuestion.id) : null

    const handleNext = () => {
        if (currentQuestionIndex < quizData.quiz.questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        };
    }

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    }


    return !quizData ? <Loading /> : (
        <Container maxWidth="xl" style={{ marginTop: "2rem" }}>
            <Typography variant="h4" gutterBottom>
                {"Revisão do Quiz"}
            </Typography>

            <Stepper style={{ borderRadius: '8px' }} nonLinear activeStep={currentQuestionIndex} alternativeLabel={true}>
                {quizData.quiz.questions.map((q, index) => {

                    const correct = quizData.answers.find(answer => answer.question.id === q.id).correct

                    return (
                        <Step key={index}>
                            <StepButton onClick={() => { setCurrentQuestionIndex(index) }}>
                                {<StepLabel StepIconProps={{ correct }} StepIconComponent={CustomStepIcon}>{`Questão ${index + 1}`}</StepLabel>}
                            </StepButton>
                        </Step>
                    )
                })}
            </Stepper>

            <QuizQuestionReview currentQuestion={quizData.quiz.questions[currentQuestionIndex]} answer={currentAnswer} />

            <Box display='flex' marginTop="2rem">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePrevious}
                    disabled={currentQuestionIndex === 0}
                    style={{ marginRight: '8px' }}
                >
                    {"Anterior"}
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={currentQuestionIndex === quizData.quiz.questions.length - 1}
                >
                    {"Próxima"}
                </Button>
                <Spacer />
            </Box>
        </Container>
    )
}

export default QuizReviewPage;