import { Visibility } from "@material-ui/icons";
import RichTextInput from "ra-input-rich-text";
import React from "react";
import {
    ArrayInput, AutocompleteArrayInput, AutocompleteInput,
    Button, Datagrid, Edit,
    FormDataConsumer,
    FormTab, ReferenceInput, ReferenceManyField, SimpleFormIterator,
    TabbedForm, TextField, TextInput,
    TopToolbar, required, useNotify, useRedirect, useRefresh
} from "react-admin";
import AddNewAttachmentButton from "../../components/add-new-attachment-button";
import AttachmentDeleteButton from "../../components/attachment-delete-button";
import { VideoUrlInput } from "../../components/video-url-input";
import { ViewFileButton } from "../../components/view-file-button";
import { videoIdExtractorTransform } from "../../utils/video-id-extractor-transform";
import SchoolActivityEditAddPartyType from "./school-activities-add-party-type";

const SchoolActivityEdit = (props) => {

    const refresh = useRefresh();
    const redirect = useRedirect();
    const notify = useNotify();

    const { tab } = props.history.location.state ? props.history.location.state : { tab: false };

    const ToolbarEdit = ({ basePath, data }) => (
        <TopToolbar>
            <Button label={'Dashboard'} onClick={() => redirect(`/school-activity-dashboard/${data.id}`)}>
                <Visibility />
            </Button>
        </TopToolbar>
    )

    const CustomTitle = ({ record }) => {
        return <span>{record ? `Atividade prática - ${record.name} - ${record.class.name}` : ''}</span>
    }

    const transform = async (data) => {
        let newData = data;
        data = videoIdExtractorTransform(newData)
        return { ...newData }
    }

    return (
        <Edit
            mutationMode="pessimistic"
            actions={<ToolbarEdit />}
            title={<CustomTitle />}
            onSuccess={(record) => {
                notify('Registro alterado com sucesso!', 'success')
                redirect(`/school-activity-dashboard/${record.data.id}`)
            }}
            transform={transform}
            {...props}
        >
            <TabbedForm>
                {!tab &&
                    <FormTab label='Dados Gerais'>
                        <TextInput source='name' label='Título' fullWidth />
                        <TextInput source='author' label='Autor' disabled fullWidth />
                        <ReferenceInput source="teacher" label="Professor" reference="users" filter={{ profile: "('Professor', 'Administrador', 'Coordenador')" }} filterToQuery={(text) => ({ name: text })} validate={[required()]} fullWidth>
                            <AutocompleteInput source="name" optionText='name' optionValue="id" label="Professor" options={{
                                suggestionsContainerProps: {
                                    modifiers: {
                                        computeStyle: {
                                            enabled: true,
                                            gpuAcceleration: false,
                                        },
                                        applyStyle: {
                                            enabled: true,
                                        },
                                    },
                                }
                            }} fullWidth />
                        </ReferenceInput>
                        <TextInput source='class.name' label='Turma' fullWidth disabled />
                        <ReferenceInput source='themes' label='Ramos do direito' perPage={100} reference="themes" fullWidth>
                            <AutocompleteArrayInput
                                source="name"
                                optionText={"name"}
                                optionValue={"id"}
                                parse={value =>
                                    value && value.map(v => ({ id: v }))
                                }
                                format={value => value && value.map(v => v.id)}
                                suppressRefError={true}
                                options={{
                                    suggestionsContainerProps: {
                                        modifiers: {
                                            computeStyle: {
                                                enabled: true,
                                                gpuAcceleration: false,
                                            },
                                            applyStyle: {
                                                enabled: true,
                                            },
                                        },
                                    }
                                }}
                                fullWidth
                            />
                        </ReferenceInput>
                    </FormTab>}

                {(!tab || tab === 'proposal') &&
                    <FormTab label={'Proposta de ensino'} path="proposal">
                        <RichTextInput source='proposal' label='Proposta de ensino/aprendizagem' />
                    </FormTab>}

                {(!tab || tab === 'prerequisites') &&
                    <FormTab label={'Pré-requisitos do aluno'} path="prerequisites">
                        <RichTextInput source='prerequisites' label='Pré-requisitos do aluno' />
                    </FormTab>}

                {(!tab || tab === 'guidelines') &&
                    <FormTab label={'Gabarito do professor'} path="guidelines">
                        <RichTextInput source='guidelines' label='Orientações' />
                        <ReferenceManyField fullWidth reference='attachments' resource='school-activity-guidelines' target='schoolActivityGuidelineId' label={'Anexos'}>
                            <Datagrid>
                                <TextField source={'name'} label={'Nome'} />
                                <ViewFileButton />
                                <FormDataConsumer>
                                    {({ formData, record }) =>
                                        <AttachmentDeleteButton path='school-activity-guidelines' resource={'attachments'} relatedId={formData.id} record={record} undoable={false} redirect={false} />
                                    }
                                </FormDataConsumer>
                            </Datagrid>
                        </ReferenceManyField>
                        <FormDataConsumer>
                            {({ record }) =>
                                <AddNewAttachmentButton record={record} resource={'school-activity-guidelines'} redirectTo={`/school-activities/${record.id}/guidelines`} fileSize={25000000} multiple={true} />
                            }
                        </FormDataConsumer>
                    </FormTab>}

                {(!tab || tab === 'details') &&
                    <FormTab label={'Orientações gerais'} path="details">
                        <RichTextInput source='details' label='Orientações gerais' />
                        <ArrayInput source="links" label="Vídeos" variant="standard">
                            <SimpleFormIterator>
                                <TextInput source="title" label="Título" validate={[required()]} fullWidth />
                                <VideoUrlInput source="url" label="URL" validate={[required()]} fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>}

                {(!tab || tab === 'parties') &&
                    <FormTab label={'Sujeitos processuais'} path="parties">
                        <ReferenceManyField fullWidth reference="party-types" target="school_activity.id" label={'Sujeitos processuais representados pelo aluno'}>
                            <Datagrid>
                                <TextField source='name' label={'Nome'} />
                            </Datagrid>
                        </ReferenceManyField>
                        <FormDataConsumer>
                            {
                                ({ formData, record, ...rest }) => {
                                    return <SchoolActivityEditAddPartyType record={record} refresh={refresh} />
                                }
                            }
                        </FormDataConsumer>
                    </FormTab>}

                {(!tab || tab === 'attachments') &&
                    <FormTab label="Anexos" path="attachments">
                        <ReferenceManyField fullWidth reference='attachments' target='schoolActivityId' label={'Anexos'}>
                            <Datagrid>
                                <TextField source={'name'} label={'Nome'} />
                                <ViewFileButton />
                                <FormDataConsumer>
                                    {({ formData, record }) =>
                                        <AttachmentDeleteButton path='school-activities' resource={'attachments'} relatedId={formData.id} record={record} undoable={false} redirect={false} />
                                    }
                                </FormDataConsumer>
                            </Datagrid>
                        </ReferenceManyField>
                        <AddNewAttachmentButton fileSize={25000000} multiple={true} />
                    </FormTab>}
            </TabbedForm>
        </Edit >
    )
}

export default SchoolActivityEdit 