import { Grid, IconButton, Paper, TextField, Typography } from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import dataProvider from "../../../providers/data-provider";
import { Edit } from "@material-ui/icons";

const QuizStats = (props) => {
    const [loading, setLoading] = useState(true)
    const [loadingPartial, setLoadingPartial] = useState(true)
    const [stats, setStats] = useState(null)
    const [partialStats, setPartialStats] = useState(null)

    const [theme, setTheme] = useState(null)
    const [themes, setThemes] = useState(null)
    const [statsTheme, setStatsTheme] = useState(null)
    const [themeSelected, setThemeSelected] = useState(false)
    const [loadingThemes, setLoadingThemes] = useState(true)
    const [loadingThemeStats, setLoadingThemeStats] = useState(true)

    useEffect(() => {
        dataProvider.get('quiz', 'quiz-user-stats')
            .then((res) => { setStats(res.data); setLoading(false) })
    }, [])

    useEffect(() => {
        dataProvider.get('quiz', 'quiz-user-stats', { filter: JSON.stringify({ limit: 10 }) })
            .then((res) => { setPartialStats(res.data); setLoadingPartial(false) })
    }, [])

    useEffect(() => {
        const fetchThemes = async () =>
            await dataProvider.get('themes', '', { filter: JSON.stringify({ has_questions: true }), sort: '"theme.name"', order: '"ASC"' })
                .then((res) => { setThemes(res.data.data); setLoadingThemes(false) })
                .finally(() => setLoadingThemes(false))
        fetchThemes()
    }, [])

    useEffect(() => {
        if (theme) dataProvider.get('quiz', 'quiz-user-stats', { filter: JSON.stringify({ theme }) })
            .then((res) => { setStatsTheme(res.data); setLoadingThemeStats(false) })
    }, [theme])

    const handleThemeChange = (event, value) => { setTheme(value); setThemeSelected(true); setLoadingThemeStats(true) }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
                <Paper style={{ minHeight: '250px' }}>
                    <Grid container item direction="column" style={{ paddingInline: '16px', paddingBlock: '32px' }}>
                        <Typography variant="h5">Estatísticas parciais</Typography>
                        <Typography variant="caption" style={{ marginBottom: '16px' }}>Últimos 10 realizados</Typography>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Avaliações realizadas:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loadingPartial ? <Skeleton /> : `${partialStats.total_quizzes}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Questões resolvidas:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loadingPartial ? <Skeleton /> : `${partialStats.total_questions}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Quantidade de acertos:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loadingPartial ? <Skeleton /> : `${partialStats.correct_questions}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Taxa de acertos:`}</Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loadingPartial ? <Skeleton /> : partialStats.total_questions > 0 ? `${(100 * (partialStats.correct_questions / partialStats.total_questions)).toFixed(2)}%` : '-'}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Paper style={{ minHeight: '250px' }}>
                    <Grid container item direction="column" style={{ paddingInline: '16px', paddingBlock: '32px' }}>
                        <Typography variant="h5">Estatísticas totais</Typography>
                        <Typography variant="caption" style={{ marginBottom: '16px' }}>Todas as avaliações</Typography>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Avaliações realizadas:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.total_quizzes}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Questões resolvidas:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.total_questions}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Quantidade de acertos:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.correct_questions}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Taxa de acertos:`}</Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : stats.total_questions > 0 ? `${(100 * (stats.correct_questions / stats.total_questions)).toFixed(2)}%` : '-'}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Paper style={{ minHeight: '250px' }}>
                    <Grid container item direction="column" style={{ paddingInline: '16px', paddingBlock: '32px' }}>
                        <Typography variant="h5">Estatísticas por área</Typography>
                        <Grid item>
                            {themeSelected ? <>
                                <Typography variant="caption" style={{ marginBottom: '16px', alignItems: 'center', marginRight: '8px' }}>{theme ? `Área do direito: ${theme.name}` : `Carregando.`}</Typography>
                                <IconButton disableRipple size="small" onClick={() => setThemeSelected(false)}><Edit /></IconButton>
                            </>
                                :
                                <>
                                    <Autocomplete
                                        id='combobox-select'
                                        options={themes}
                                        getOptionLabel={c => c.name || 'Selecione...'}
                                        getOptionSelected={(o, s) => o.id === s.id}
                                        noOptionsText={'Sem resultados'}
                                        value={theme}
                                        size={'small'}
                                        onChange={handleThemeChange}
                                        disabled={loadingThemes}
                                        renderInput={(params) => <TextField {...params} label={'Área do direito'} />}
                                    />
                                </>
                            }
                        </Grid>

                        {statsTheme && <>
                            <Grid container item>
                                <Typography variant="body1" style={{ marginRight: '16px' }}>{`Avaliações realizadas:`} </Typography>
                                <Typography variant="body1" style={{ width: '20px' }}>{loadingThemeStats ? <Skeleton /> : `${statsTheme.total_quizzes}`}</Typography>
                            </Grid>
                            <Grid container item>
                                <Typography variant="body1" style={{ marginRight: '16px' }}>{`Questões resolvidas:`} </Typography>
                                <Typography variant="body1" style={{ width: '20px' }}>{loadingThemeStats ? <Skeleton /> : `${statsTheme.total_questions}`}</Typography>
                            </Grid>
                            <Grid container item>
                                <Typography variant="body1" style={{ marginRight: '16px' }}>{`Quantidade de acertos:`} </Typography>
                                <Typography variant="body1" style={{ width: '20px' }}>{loadingThemeStats ? <Skeleton /> : `${statsTheme.correct_questions}`}</Typography>
                            </Grid>
                            <Grid container item>
                                <Typography variant="body1" style={{ marginRight: '16px' }}>{`Taxa de acertos:`}</Typography>
                                <Typography variant="body1" style={{ width: '20px' }}>{loadingThemeStats ? <Skeleton /> : statsTheme.total_questions > 0 ? `${(100 * (statsTheme.correct_questions / statsTheme.total_questions)).toFixed(2)}%` : '-'}</Typography>
                            </Grid>
                        </>}
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default QuizStats;