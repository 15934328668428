import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { Title, useAuthenticated, useRedirect } from 'react-admin';
import QuizInstitutionList from './quiz-institution-list.component';
import QuizInstitutionQuestionDatabaseInfo from './quiz-institution-question-database-info.component';
import QuizInstitutionStats from './quiz-institution-stats.component';
import QuizList from './quiz-list.component';
import QuizStats from './quiz-stats.component';


const QuizDashboard = ({ isStudent, ...props }) => {

    useAuthenticated()
    const redirect = useRedirect()
    return (
        <Grid container>
            <Title title={'Juristta Quiz'} />
            {isStudent && <Button style={{ width: '150px', marginBottom: '32px' }} onClick={() => redirect('/quiz')} variant="contained" color="primary">Novo Quiz</Button>}
            {isStudent ? <QuizStats /> : <QuizInstitutionStats />}
            {!isStudent && <QuizInstitutionQuestionDatabaseInfo />}
            {isStudent ? <QuizList /> : <QuizInstitutionList />}
        </Grid >
    )
}

export default QuizDashboard;