import { Delete } from "@material-ui/icons";
import axios from "axios";
import React from "react";
import { ArrayField, BooleanField, BooleanInput, Button, Datagrid, Edit, FormDataConsumer, FormTab, PasswordInput, ReferenceManyField, TabbedForm, TextField, TextInput, useRefresh } from "react-admin";
import MaskedInput from "../../components/masked-input";
import cookiesProvider from "../../providers/cookies-provider";
import { storageProvider } from "../../providers/storage-provider";
import { getApiUrl } from "../../utils/urls";
import UserEditAddInstitution from "./user-add-institution";
import UserEditAddProfile from "./user-add-profile";

const UserEditRemoveRelated = ({ user, record, refresh, resource, ...props }) => {

    const apiUrl = getApiUrl()
    const token = cookiesProvider.getToken()

    const handleClick = async () => {
        axios.delete(
            `${apiUrl}/users/${user}/${resource}/${record.id}`,
            { headers: { Authorization: `Bearer ${token}` } }
        ).then(() => { refresh() })
    }

    return (
        <Button label={'Remover'} onClick={handleClick}>
            <Delete />
        </Button>
    )
}

const UserEdit = (props) => {
    const refresh = useRefresh();

    const CustomTitle = ({ record }) => {
        return <span>{record.name}</span>
    }

    const superAdmin = storageProvider.getCurrentUser()?.superAdmin;
    const emailHelperText = 'Para melhor utilização do sistema de notificações via email, não recomendamos a utilização de endereços de email @outlook, @hotmail e @live.';

    return (
        <Edit
            mutationMode="pessimistic"
            {...props}
            title={<CustomTitle />}
        >
            <TabbedForm>
                <FormTab label={'Dados Gerais'}>
                    <TextInput source="name" label={'Nome'} fullWidth />
                    <TextInput source="email" label={'Email'} type={'email'} helperText={emailHelperText} fullWidth />
                    <MaskedInput source="phone" onlyNumbers={true} mask={'(99) 99999-9999'} label={'Telefone'} fullWidth />
                    <BooleanInput source="active" label={'Ativo?'} fullWidth />
                    {
                        superAdmin &&
                        <>
                            <PasswordInput source="password" label={'Senha'} fullWidth />
                            <PasswordInput source="passwordConfirmation" label={'Confirme a senha'} fullWidth />
                        </>
                    }


                </FormTab>
                {superAdmin && <FormTab label={'Instituições'} path="institutions">
                    <ReferenceManyField fullWidth reference="institutions" target="user.id" label={'Instituições'}>
                        <Datagrid>
                            <TextField source='name' label={'Nome'} />
                            <FormDataConsumer>
                                {
                                    ({ formData, record, ...rest }) => {
                                        return <UserEditRemoveRelated resource={'institution'} record={record} user={formData.id} refresh={refresh} />
                                    }
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                    <UserEditAddInstitution refresh={refresh} />
                </FormTab>}
                <FormTab label={'Perfis'} path="profiles">
                    <ReferenceManyField fullWidth reference="profiles" target="user.id" label={'Perfis'}>
                        <Datagrid>
                            <TextField source='name' label={'Nome'} />
                            <FormDataConsumer>
                                {
                                    ({ formData, record, ...rest }) => {
                                        return <UserEditRemoveRelated resource={'profile'} record={record} user={formData.id} refresh={refresh} />
                                    }
                                }
                            </FormDataConsumer>
                        </Datagrid>
                    </ReferenceManyField>
                    <FormDataConsumer>
                        {
                            ({ formData, record, ...rest }) => {
                                return record?.profiles?.length === 0 && <UserEditAddProfile record={record} refresh={refresh} />
                            }
                        }
                    </FormDataConsumer>

                </FormTab>
                <FormTab label={'Turmas'}>
                    <ArrayField source="classes" label={'Turmas'} fullWidth>
                        <Datagrid>
                            <TextField source='name' label={'Nome'} />
                            <BooleanField source='active' label={'Ativa?'} />
                        </Datagrid>
                    </ArrayField>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default UserEdit;