import { Grid, IconButton, Paper, TextField, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import dataProvider from "../../../providers/data-provider";

const QuizInstitutionStats = (props) => {

    const notify = useNotify()

    const [stats, setStats] = useState(null)
    const [loading, setLoading] = useState(true)

    const [academicPeriod, setAcademicPeriod] = useState(null)
    const [academicPeriods, setAcademicPeriods] = useState(null)
    const [statsAcademicPeriod, setStatsAcademicPeriod] = useState(null)
    const [academicPeriodSelected, setAcademicPeriodSelected] = useState(true)
    const [loadingAcademicPeriods, setLoadingAcademicPeriods] = useState(true)
    const [loadingAcademicPeriodStats, setLoadingAcademicPeriodStats] = useState(true)

    const [theme, setTheme] = useState(null)
    const [themes, setThemes] = useState(null)
    const [statsTheme, setStatsTheme] = useState(null)
    const [themeSelected, setThemeSelected] = useState(false)
    const [loadingThemes, setLoadingThemes] = useState(true)
    const [loadingThemeStats, setLoadingThemeStats] = useState(true)

    useEffect(() => {
        dataProvider.get('quiz', 'quiz-institution-stats', { filter: JSON.stringify({ limit: 10 }) })
            .then((res) => { setStats(res.data); setLoading(false) })
    }, [])

    useEffect(() => {
        dataProvider.get('academic-periods', '', { sort: '"academic-period.start_at"', order: '"DESC"' })
            .then(res => { setAcademicPeriods(res.data.data); setAcademicPeriod(res.data.data[0]); setLoadingAcademicPeriods(false) })
            .catch(error => notify('Erro ao buscar dados, tente novamente.', 'error'))
            .finally(() => setLoadingAcademicPeriods(false))
    }, [notify])

    useEffect(() => {
        if (academicPeriod) dataProvider.get('quiz', 'quiz-institution-stats', { filter: JSON.stringify({ start_at: academicPeriod.start_at, end_at: academicPeriod.end_at }) })
            .then((res) => { setStatsAcademicPeriod(res.data); setLoadingAcademicPeriodStats(false) })
    }, [academicPeriod])

    useEffect(() => {
        if (theme) dataProvider.get('quiz', 'quiz-institution-stats', { filter: JSON.stringify({ theme }) })
            .then((res) => { setStatsTheme(res.data); setLoadingThemeStats(false) })
    }, [theme])

    useEffect(() => {
        const fetchThemes = async () =>
            await dataProvider.get('themes', '', { filter: JSON.stringify({ has_questions: true }), sort: '"theme.name"', order: '"ASC"' })
                .then((res) => { setThemes(res.data.data); setLoadingThemes(false) })
                .catch((error) => notify('Erro ao buscar dados, tente novamente.', 'error'))
                .finally(() => setLoadingThemes(false))
        fetchThemes()
    }, [notify])

    const handleAcademicPeriodChange = (event, value) => { setAcademicPeriod(value); setAcademicPeriodSelected(true); setLoadingAcademicPeriodStats(true) }
    const handleThemeChange = (event, value) => { setTheme(value); setThemeSelected(true); setLoadingThemeStats(true) }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
                <Paper style={{ minHeight: '250px' }}>
                    <Grid container item direction="column" style={{ paddingInline: '16px', paddingBlock: '32px' }}>
                        <Typography variant="h5">Estatísticas parciais</Typography>
                        <Typography variant="caption" style={{ marginBottom: '16px' }}>Últimos 50 realizados</Typography>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Avaliações realizadas:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.total_quizzes}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Questões resolvidas:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.total_questions}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Quantidade de acertos:`} </Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : `${stats.correct_questions}`}</Typography>
                        </Grid>
                        <Grid container item>
                            <Typography variant="body1" style={{ marginRight: '16px' }}>{`Taxa de acertos:`}</Typography>
                            <Typography variant="body1" style={{ width: '20px' }}>{loading ? <Skeleton /> : stats.total_questions > 0 ? `${(100 * (stats.correct_questions / stats.total_questions)).toFixed(2)}%` : '-'}</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} >
                <Paper style={{ minHeight: '250px' }}>
                    <Grid container item direction="column" style={{ paddingInline: '16px', paddingBlock: '32px' }}>
                        <Typography variant="h5">Estatísticas por período letivo</Typography>
                        <Grid item>
                            {academicPeriodSelected ? <>
                                <Typography variant="caption" style={{ marginBottom: '16px', alignItems: 'center', marginRight: '8px' }}>
                                    {academicPeriod ? `${academicPeriod.name} (${new Date(academicPeriod.start_at).toLocaleDateString()} - ${new Date(academicPeriod.end_at).toLocaleDateString()})` : 'Carregando'}
                                </Typography>
                                <IconButton disableRipple disabled={loadingAcademicPeriods} size="small" onClick={() => setAcademicPeriodSelected(false)}><Edit /></IconButton>
                            </>
                                :
                                <>
                                    <Autocomplete
                                        id='combobox-select'
                                        options={academicPeriods}
                                        getOptionLabel={c => c.name || 'Selecione...'}
                                        getOptionSelected={(o, s) => o.id === s.id}
                                        noOptionsText={'Sem resultados'}
                                        value={academicPeriod}
                                        size={'small'}
                                        onChange={handleAcademicPeriodChange}
                                        renderInput={(params) => <TextField {...params} label={'Período letivo'} />}
                                    />
                                </>
                            }
                        </Grid>
                        {statsAcademicPeriod && <>
                            <Grid container item>
                                <Typography variant="body1" style={{ marginRight: '16px' }}>{`Avaliações realizadas:`} </Typography>
                                <Typography variant="body1" style={{ width: '20px' }}>{loadingAcademicPeriodStats ? <Skeleton /> : `${statsAcademicPeriod.total_quizzes}`}</Typography>
                            </Grid>
                            <Grid container item>
                                <Typography variant="body1" style={{ marginRight: '16px' }}>{`Questões resolvidas:`} </Typography>
                                <Typography variant="body1" style={{ width: '20px' }}>{loadingAcademicPeriodStats ? <Skeleton /> : `${statsAcademicPeriod.total_questions}`}</Typography>
                            </Grid>
                            <Grid container item>
                                <Typography variant="body1" style={{ marginRight: '16px' }}>{`Quantidade de acertos:`} </Typography>
                                <Typography variant="body1" style={{ width: '20px' }}>{loadingAcademicPeriodStats ? <Skeleton /> : `${statsAcademicPeriod.correct_questions}`}</Typography>
                            </Grid>
                            <Grid container item>
                                <Typography variant="body1" style={{ marginRight: '16px' }}>{`Taxa de acertos:`}</Typography>
                                <Typography variant="body1" style={{ width: '20px' }}>{loadingAcademicPeriodStats ? <Skeleton /> : statsAcademicPeriod.total_questions > 0 ? `${(100 * (statsAcademicPeriod.correct_questions / statsAcademicPeriod.total_questions)).toFixed(2)}%` : '-'}</Typography>
                            </Grid>
                        </>}
                    </Grid>
                </Paper>
            </Grid >
            <Grid item xs={12} sm={12} md={4}>
                <Paper style={{ minHeight: '250px' }}>
                    <Grid container item direction="column" style={{ paddingInline: '16px', paddingBlock: '32px' }}>
                        <Typography variant="h5">Estatísticas por área</Typography>
                        <Grid item>

                            {themeSelected ? <>
                                <Typography variant="caption" style={{ marginBottom: '16px', alignItems: 'center', marginRight: '8px' }}>{theme ? `Área do direito: ${theme.name}` : `Carregando.`}</Typography>
                                <IconButton disableRipple size="small" onClick={() => setThemeSelected(false)}><Edit /></IconButton>
                            </>
                                :
                                <>
                                    <Autocomplete
                                        id='combobox-select'
                                        options={themes}
                                        getOptionLabel={c => c.name || 'Selecione...'}
                                        getOptionSelected={(o, s) => o.id === s.id}
                                        noOptionsText={'Sem resultados'}
                                        value={theme}
                                        size={'small'}
                                        onChange={handleThemeChange}
                                        disabled={loadingThemes}
                                        renderInput={(params) => <TextField {...params} label={'Área do direito'} />}
                                    />
                                </>
                            }
                        </Grid>

                        {statsTheme && <>
                            <Grid container item>
                                <Typography variant="body1" style={{ marginRight: '16px' }}>{`Avaliações realizadas:`} </Typography>
                                <Typography variant="body1" style={{ width: '20px' }}>{loadingThemeStats ? <Skeleton /> : `${statsTheme.total_quizzes}`}</Typography>
                            </Grid>
                            <Grid container item>
                                <Typography variant="body1" style={{ marginRight: '16px' }}>{`Questões resolvidas:`} </Typography>
                                <Typography variant="body1" style={{ width: '20px' }}>{loadingThemeStats ? <Skeleton /> : `${statsTheme.total_questions}`}</Typography>
                            </Grid>
                            <Grid container item>
                                <Typography variant="body1" style={{ marginRight: '16px' }}>{`Quantidade de acertos:`} </Typography>
                                <Typography variant="body1" style={{ width: '20px' }}>{loadingThemeStats ? <Skeleton /> : `${statsTheme.correct_questions}`}</Typography>
                            </Grid>
                            <Grid container item>
                                <Typography variant="body1" style={{ marginRight: '16px' }}>{`Taxa de acertos:`}</Typography>
                                <Typography variant="body1" style={{ width: '20px' }}>{loadingThemeStats ? <Skeleton /> : statsTheme.total_questions > 0 ? `${(100 * (statsTheme.correct_questions / statsTheme.total_questions)).toFixed(2)}%` : '-'}</Typography>
                            </Grid>
                        </>}
                    </Grid>
                </Paper>
            </Grid>
        </Grid >
    )
}

export default QuizInstitutionStats;