import {
    Box,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import React from "react";
import { Spacer } from "../../components/spacer";

const QuizQuestion = ({ currentQuestion, answers, answered, setAnswers, setAnswered, disabled }) => {

    const handleAnswerChange = (alternativeId) => {
        setAnswers({
            ...answers,
            [currentQuestion.id]: alternativeId,
        });
        setAnswered({
            ...answered,
            [currentQuestion.id]: true
        })
    };


    return (
        <Box marginTop="2rem">
            <Grid container>
                <Typography variant="h6" color="textSecondary" align="justify">
                    {`(${currentQuestion?.source?.name?.trim()})`}
                </Typography>
                <Spacer />
                <Typography variant="h6" color="textSecondary" align="justify">
                    {`${currentQuestion?.theme?.name?.trim()}`}
                </Typography>
            </Grid>
            <Divider style={{ marginBlock: '8px' }} />
            <Typography variant="h6" color="textSecondary" align="justify" gutterBottom>
                {`${currentQuestion.text}`}
            </Typography>
            {/*currentQuestion.observations && (
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {currentQuestion.observations}
                </Typography>
            )*/}

            <FormControl component="fieldset">
                <RadioGroup
                    style={{ marginTop: '2rem' }}
                    value={answers[currentQuestion.id] || ""}
                    onChange={(e) => handleAnswerChange(e.target.value)}
                >
                    {currentQuestion.alternatives.map((alt) => (
                        <FormControlLabel
                            style={{ marginBlock: '0.5rem' }}
                            key={alt.id}
                            value={alt.id}
                            control={<Radio />}
                            label={alt.text}
                            disabled={disabled}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Box>
    )
}

export default QuizQuestion;